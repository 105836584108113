<template>
  <div class="row">
    <div class="col">
      <h4>FUNCIONARIOS</h4>
      <app-table-registers
        :getList="getList"
        ref="tr"
        @btnNew="
          $refs.elForm.reload();
          $refs.elModalForm.show();
        "
      >
        <template slot="table">
          <thead>
            <tr>
              <th>ID</th>
              <th></th>
              <th>NOMBRE</th>
              <th>AREA</th>
              <th>ESPECIALIDADES</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(l, i) in list.data" :key="i">
              <td>{{ l.id }}</td>
              <td>
                <app-img-viewer
                  class="img-xs rounded-circle"
                  v-if="l.avatar_file_id"
                  :fileId="l.avatar_file_id"
                ></app-img-viewer>
              </td>
              <td>{{ l.name }}</td>
              <td>{{ l.area_name }}</td>
              <td>
                <span
                  class="badge badge-primary"
                  v-for="l1 in l.specialties"
                  :key="l1.id"
                  >{{ l1.name }}
                </span>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <button
                    class="btn btn-light btn-sm"
                    @click="
                      $refs.elModalForm.show();
                      $refs.elForm.load(l);
                    "
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-light"
                    @click="
                      $refs.elQuestionDel.val = l.id;
                      $refs.elQuestionDel.show();
                    "
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </app-table-registers>
    </div>

    <app-modal-basic ref="elModalForm" :lg="true">
      <Form
        ref="elForm"
        @submitted="
          $refs.elModalForm.hide();
          reload();
        "
      ></Form>
    </app-modal-basic>

    <app-modal-yn
      ref="elQuestionDel"
      @yes="deleteEmployee($refs.elQuestionDel.val)"
    >
      <p>Eliminar empleado?</p>
    </app-modal-yn>
  </div>
</template>
<script>
// import { GeneralService } from "../../general-module/GeneralService";
import { AdminService } from "../AdminService";
import Form from "./Form";

export default {
  components: {
    Form
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: {}
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    deleteEmployee(id) {
      AdminService.deleteEmployee(id).then(() => {
        this.reload();
      });
    },
    reload() {
      this.$refs.tr.gl();
    },
    async getList(params) {
      let res = await AdminService.getEmployees(params);
      this.list = res;
      return res;
    }
  }
};
</script>

<style></style>
