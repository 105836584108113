<template>
  <div>
    <button
      class="btn btn-sm btn-secondary mb-1 mr-1"
      v-for="(l, i) in list"
      :key="l.id"
      @click="
        l.to_delete = 1;
        removeSpecialty(l.id, i);
      "
      :disabled="l.to_delete"
    >
      <span>{{ l.name }} </span>
      <i class="fa fa-close"></i>
    </button>

    <div class="my-1" v-show="loading">
      <i class="fa fa-spinner fa-spin"></i> Agregando
    </div>

    <selectSpecialty
      ref="selSpe"
      class="my-2"
      v-model="specialtyId"
    ></selectSpecialty>
    <button class="btn btn-sm btn-secondary" @click="addSpecialty()">
      <i class="fa fa-plus"></i> Agregar Especialidad
    </button>
  </div>
</template>
<script>
import { AdminService } from "../../AdminService";
import selectSpecialty from "../../../general-module/specialties/Select";

export default {
  components: {
    selectSpecialty
  },

  // directives
  // filters

  props: {
    employeeId: {}
  },

  data: () => ({
    list: [],
    specialtyId: null,
    loading: false
  }),

  computed: {
    //
  },

  watch: {
    employeeId: {
      immediate: true,
      handler() {
        this.getContent();
      }
    }
  },

  mounted() {},

  methods: {
    removeSpecialty(specialty_id, index) {
      AdminService.saveEmployeeSpecialty({
        remove_specialty: 1,
        employee_id: this.employeeId,
        specialty_id: specialty_id
      }).then(() => {
        this.list.splice(index, 1);
      });
    },
    addSpecialty() {
      this.loading = true;
      AdminService.saveEmployeeSpecialty({
        employee_id: this.employeeId,
        specialty_id: this.specialtyId
      }).then(added => {
        if (added) {
          this.list.push({
            id: this.$refs.selSpe.val.id,
            name: this.$refs.selSpe.val.name
          });
        }
        this.loading = false;
      });
    },
    getContent() {
      AdminService.getEmployeeSpecialties(this.employeeId).then(list => {
        this.list = list;
      });
    }
  }
};
</script>

<style></style>
